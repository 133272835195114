import React, { useState, useEffect } from 'react'
import * as JsSearch from 'js-search'
import { CardsSection, SearchBar } from '../components'
import Layout from '../templates/layout'
import useCourses from '../hooks/useCourses'

const AcademicOffer = () => {

  const dataCourses = useCourses()
  const fjsCourses = dataCourses?.allStrapiFjsCourses.nodes

  const [queryResults, setQueryResults] = useState(fjsCourses)
  const [search, setSearch] = useState(null)
  
  const termFrequency = true
  const selectedSanitizer = "Lower Case"
  const removeStopWords = true


  const rebuildIndex = () => {
    const dataToSearch = new JsSearch.Search('name')

    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
        dataToSearch.tokenizer
      )
    }

    dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()

    selectedSanitizer === 'Case Sensitive'
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('name'))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())

    
    dataToSearch.addIndex('name')
    dataToSearch.addIndex(['training_type', 'title'])
    dataToSearch.addIndex(['academic_unit', 'name'])
    dataToSearch.addIndex(['seo', 'pageKeywords'])
    dataToSearch.addIndex('idSIUCC')
    dataToSearch.addDocuments(fjsCourses) // adds the data to be searched
    setSearch(dataToSearch)
  }

  const handleSearch = (textInput) => {
    textInput.length === 0 ? setQueryResults(fjsCourses) : setQueryResults(search.search(textInput))
    textInput.length === 0
  }

  useEffect(() => {
    rebuildIndex()
  }, [])

  const newCourses = queryResults.filter(
    (course) => course.dateStart >= new Date().toISOString()
  )

  const expiredCourses = queryResults.filter(
    (course) => course.dateStart < new Date().toISOString()
  ).reverse()

  return (
    <Layout>
      <SearchBar onQuery={handleSearch} placeholder={'Buscá tu curso por nombre, temática o unidad académica'} />
      <CardsSection courses={newCourses} areas={true} id={'courses'}/>
      {expiredCourses.length > 0 && (
        <CardsSection
          courses={expiredCourses}
          related={true}
          title={'Cursos comenzados'}
          id={'cursos_comenzados'}
        />
      )}
    </Layout>
  )
}

export default AcademicOffer
